var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.user")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card public-profile border-0 rounded shadow",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-3 text-md-left text-center"
  }, [_vm.profile_picture ? _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "bg-light shadow",
    staticStyle: {
      "border": "1px solid #eee",
      "position": "relative",
      "display": "inline-block",
      "border-radius": "100%",
      "height": "150px",
      "width": "150px",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "background-position": "center center"
    },
    style: 'background-image: url(' + _vm.profile_picture + ')'
  })]) : _vm._e()]), _c('div', {
    staticClass: "col-lg-9 col-md-9"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('div', {
    staticClass: "col-md-12 text-md-left text-center mt-4 mt-sm-0"
  }, [_c('h5', {
    staticClass: "title mb-0"
  }, [_vm._v(" " + _vm._s(_vm.currentUser ? _vm.currentUser.designation : "") + " " + _vm._s(_vm.currentUser ? _vm.currentUser.name : "-") + " "), _c('small', [_vm.currentUser.status == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(" " + _vm._s(_vm.$t("active")) + " ")]) : _vm._e(), _vm.currentUser.status != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t("inactive")) + " ")]) : _vm._e()])]), _c('small', {
    staticClass: "text-muted h6 mr-2"
  }, [_vm._v(" " + _vm._s(_vm.currentUser.roles ? _vm.currentUser.roles[0].name : "-") + " ")]), _c('ul', {
    staticClass: "list-inline mb-0 mt-3"
  }, [_c('li', {
    staticClass: "list-inline-item mr-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": "/profile-edit"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.update-details")) + " "), _c('i', {
    staticClass: "mdi mdi-chevron-right"
  })])], 1)])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card bg-light border-0 rounded shadow",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "card-bodyz"
  }, [_c('ul', {
    staticClass: "nav nav-tabs"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.routeName == 'profile' ? 'active' : '',
    attrs: {
      "to": {
        name: 'profile'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("profile.information")))])], 1), _vm.userRole != 'Client' ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.routeName == 'profile.signature' ? 'active' : '',
    attrs: {
      "to": {
        name: 'profile.signature'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("profile.signature")))])], 1) : _vm._e()]), _c('div', {
    staticClass: "card-body"
  }, [_c('router-view')], 1)])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }